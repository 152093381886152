import { I18n } from 'i18n-js';

const i18n = new I18n();

const languageCodes = [window.defaultLocale, window.locale];

Promise.all(
  languageCodes.map((languageCode) =>
    import(`../../../public/javascripts/i18n/${languageCode}.json`)
  )
).then((modules) => modules.forEach((module) => i18n.store(module)));

i18n.enableFallback = true;
i18n.defaultLocale = window.defaultLocale;
i18n.locale = window.locale;

window.I18n = i18n;
